import React from 'react'
import { AppProvider } from './src/context'

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>

const onInitialClientRender = () => {
    const isEDMode = typeof window !== `undefined` ? window.location.href.indexOf('ed=1') > -1 : null
    const el_body = document.querySelector('body')

    if (isEDMode) {
        el_body.classList.add('ed')
    } else {
        el_body.classList.remove('ed')
    }
}
export { onInitialClientRender }

export const onRouteUpdate = ({ location }) => {
    const isES = typeof window !== `undefined` ? location.href.indexOf('/es') > -1 : null
    const el_body = document.querySelector('body')

    if (isES) {
        el_body.classList.add('lang-es')
    } else {
        el_body.classList.remove('lang-es')
    }

    // Hashes which are causing issues for scroll on page load or route change
    const hashes = [
      "#watch",
      "#ver",
      "#find-a-doctor",
      "#encuentre-un-medico",
    ]

    if (typeof window !== `undefined` && hashes.includes(location.hash)) {
      setTimeout(() => {
        scrollToAnchor(location)
      }, 300)
    }
}

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location) {
    // Check for location so build does not fail
    if (location && location.hash) {
        const item = document.querySelector(`${location.hash}`)
        if (!item) return

        // Get the top position of the element
        const itemBound = item.getBoundingClientRect().top + window.scrollY
        const itemMargin = parseInt(window.getComputedStyle(item).marginTop)
        const itemPadding = parseInt(window.getComputedStyle(item).paddingTop)
        const itemTop = itemBound + itemMargin + itemPadding
        // Get the height of a fixed header if there is one
        const header = document.getElementById('header')
        const headerHeight = header.getBoundingClientRect().height || 0
        // Calculate the scroll position
        const scrollTarget = Math.round(itemTop - headerHeight - 30)

        // Scroll to the correct position
        // Use an interval to confirm the scroll has not been interrupted
        // Cancel the interval after 10 attempts
        let scrollAttempts = 0
        const interval = setInterval(() => {
          scrollAttempts++
            if (window.scrollY === scrollTarget || scrollAttempts > 1) {
                clearInterval(interval)
                return
            }
            window.scrollTo({
                top: scrollTarget,
                behavior: 'instant',
              })
        }, 100)
    }

    return true
}
